import React, { useContext, useState, useEffect, useCallback } from 'react';
import { navigate, PageProps } from 'gatsby';
import { LayoutMissionMobile, SEO, LayoutMissionDesktop } from '@components';
import Step1 from '@components/Budget/Mision3/Step1/Step1';
import BudgetMission3Subtitle from '@components/Budget/Mision3/BudgetMission3Subtitle/BudgetMission3Subtitle';
import Step2 from '@components/Budget/Mision3/Step2/Step2';
import Step3 from '@components/Budget/Mision3/Step3/Step3';
import { useMediaQuery } from '@material-ui/core';
import { AppContext } from '@context';
import { useApolloClient } from '@apollo/client';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { Route } from '@interfaces';
import { useAuth } from '@hooks';

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const [isPage, setPage] = useState(0);
  const isMobile = useMediaQuery('(min-width: 1270px)');
  const [step1Data, setStep1Data] = useState<any>({});
  const [step2Data, setStep2Data] = useState<any>({});
  const { openCongratsModal } = useContext(AppContext);
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const [step1family, setSetStep1family] = useState(0);
  const [step1house, setSetStep1house] = useState(0);
  const [step2variableExpenses, setStep2variableExpenses] = useState(0);

  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.BudgetCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m3.id
  )[0];

  const data = () => {
    return {
      total: average(),
      fixed: {
        total: fixedAvg(),
        ...Object.values(step1Data),
      },
      variable: {
        total: variableAvg(),
        ...Object.values(step2Data),
      },
    };
  };

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id, data());
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  const average = () => {
    return fixedAvg() + variableAvg();
  };

  const fixedAvg = () => {
    const amounts = [];
    Object.values(step1Data).forEach((element) => {
      element.forEach((ele) => {
        if (ele.total > 0) {
          amounts.push(ele.total);
        }
      });
    });
    if (amounts.length > 0) {
      return amounts.reduce((a, b) => a + b);
    } else {
      return amounts;
    }
  };

  const variableAvg = () => {
    const amounts = [];
    Object.values(step2Data).forEach((element) => {
      element.forEach((ele) => {
        if (ele.total > 0) {
          amounts.push(ele.total);
        }
      });
    });
    if (amounts.length > 0) {
      return amounts.reduce((a, b) => a + b);
    } else {
      return amounts;
    }
  };

  return (
    <>
      <SEO />
      {isMobile ? (
        <>
          {isPage === 0 ? (
            <LayoutMissionDesktop
              title="Gastos fijos y variables"
              subtitle="Los gastos fijos son gastos básicos que debes pagar todos los meses. Algunos ejemplos son la luz, el arriendo y el agua."
              number={3}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.budget)}
            >
              <BudgetMission3Subtitle title="Simulador">
                Te ayudaremos a armar tu presupuesto. Identifica tu(s) gasto(s)
                fijo(s, y recuerda que{' '}
                <strong>puedes dejar todos los que quieras vacíos.</strong>
              </BudgetMission3Subtitle>

              <Step1
                setSetStep1family={setSetStep1family}
                setSetStep1house={setSetStep1house}
                onClick={(data) => {
                  setStep1Data(data);
                  setPage(1);
                }}
              />
            </LayoutMissionDesktop>
          ) : isPage === 1 ? (
            <LayoutMissionDesktop
              secondLayoutForShortcuts
              title="Gastos fijos y variables"
              subtitle={`Los gastos variables son cosas en las que gastas frecuentemente pero
            no son esenciales. ${` `}
            Algunos ejemplos son la ropa, la entretención y
            las vacaciones.`}
              number={3}
              setPage={setPage}
              pageNumber={0}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.budget)}
            >
              <BudgetMission3Subtitle title="Simulador">
                Te ayudaremos a armar tu presupuesto. Identifica tu(s) gasto(s)
                variable(s), y recuerda que{' '}
                <strong>puedes dejar todos los que quieras vacíos.</strong>
              </BudgetMission3Subtitle>
              <Step2
                setStep2variableExpenses={setStep2variableExpenses}
                onClick={(data) => {
                  setStep2Data(data);
                  setPage(2);
                }}
              />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionDesktop
              title="Gastos fijos y variables"
              subtitle=""
              number={3}
              secondLayoutForShortcuts
              setPage={setPage}
              pageNumber={1}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.budget)}
            >
              <Step3
                fixedExpenses={step1family + step1house}
                variableExpenses={step2variableExpenses}
                data={data()}
                onClick={() => handleClick()}
              />
            </LayoutMissionDesktop>
          )}
        </>
      ) : (
        <>
          {isPage === 0 ? (
            <LayoutMissionMobile
              title="Gastos fijos y variables"
              number={3}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.budget)}
            >
              <BudgetMission3Subtitle title="Simulador">
                Te ayudaremos a armar tu presupuesto. Identifica tu(s) gasto(s)
                fijo(s, y recuerda que{' '}
                <strong>puedes dejar todos los que quieras vacíos.</strong>
              </BudgetMission3Subtitle>

              <Step1
                setSetStep1family={setSetStep1family}
                setSetStep1house={setSetStep1house}
                onClick={() => {
                  setPage(1);
                }}
              />
            </LayoutMissionMobile>
          ) : isPage === 1 ? (
            <LayoutMissionMobile
              title="Gastos fijos y variables"
              number={3}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.budget)}
            >
              <BudgetMission3Subtitle title="Simulador">
                Te ayudaremos a armar tu presupuesto. Identifica tu(s) gasto(s)
                variable(s), y recuerda que{' '}
                <strong>puedes dejar todos los que quieras vacíos.</strong>
              </BudgetMission3Subtitle>
              <Step2
                setStep2variableExpenses={setStep2variableExpenses}
                onClick={() => {
                  setPage(2);
                }}
              />
            </LayoutMissionMobile>
          ) : (
            <LayoutMissionMobile
              title="Gastos fijos y variables"
              number={3}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.budget)}
            >
              <Step3
                fixedExpenses={step1family + step1house}
                variableExpenses={step2variableExpenses}
                average={average()}
                fixedAvg={fixedAvg()}
                variableAvg={variableAvg()}
                onClick={() => handleClick()}
              />
            </LayoutMissionMobile>
          )}
        </>
      )}
    </>
  );
};

export default SaveByRokinPage;
